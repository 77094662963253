import React, { useState, useEffect } from "react";
import TopNav from "./TopNav";
import { HandleSubmit, CheckFormValidity, GetItemById } from "../../utility/Form";
import Toaster from "../Toaster/Toaster";
import { get, set, isArray } from "lodash";
import { useLocation } from "@reach/router";
import { navigate } from "@reach/router";
import LoadingComp from "./loading/Loading";

const GoToPage = (page) => {
  navigate(page)
}

const CommonEditItemComp = (props) => {
  const [Loading, setLoading] = useState(true);
  const [editItem, setEditItem] = useState({});
  const location = useLocation();
  let split_location = location.pathname.split('/');
  let get_id = split_location[split_location.length - 2];
  set(props, 'additionalParams.param', get_id);
  // Toaster
  const [showToaster, setShowToaster] = useState(false);
  const [toasterData, setToasterData] = useState({});
  const [enablePublishAction, setEnablePublishAction] = useState(true);

  const setShowToasterFunc = (value) => {
    setShowToaster(value);
    setToasterData({});
  }
  // Toaster

  useEffect(async () => {
    //Get item by id
    if (get_id) {
      try {
        const { data } = await GetItemById(`${props.parentPath}.getById`, get_id);
        setLoading(false);
        setEditItem(data);
      } catch (error) {       
        console.log('GetItemById error', error);
        setLoading(false);
      }
    }
  }, [get_id])

  const { PuFormControlsEdit } = props,
    HandleSubmitFunc = async (event, additionalParams = {}) => {
      let submittedResult = {};
      event.preventDefault()
      event.stopPropagation()
      if (additionalParams.isSubmit) {
        const Form = event.currentTarget;
        let formSubmit = {
          isSubmitted: true,
          hasError: additionalParams.enableValidation ? (Form.checkValidity() === false) : false
        };
        try {
          if (formSubmit.hasError) {
            formSubmit.error = CheckFormValidity(event, additionalParams);
          } else {
            submittedResult = await HandleSubmit(event, { ...props.additionalParams, ...additionalParams });
            if (!get(props, 'additionalParams.isAuth')) {
              setShowToaster(true);
              setToasterData({
                message: `${props.addBtnText ? props.addBtnText : 'Form'} updated successfully`,
                heading: props.menu,
                variant: 'primary'
              })
            }
            set(props, 'additionalParams.fields', {});
            setEditItem(submittedResult.data);
            setEnablePublishAction(true);
          }
          if (additionalParams.isPageRedirect && additionalParams.pageRedirectUrl) {
            GoToPage(additionalParams.pageRedirectUrl);
          }
          return Promise.resolve({ formSubmit, submittedResult });
        } catch (error) {
          const ErrMsg = get(error, 'response.data.message');
          let messageNew = ErrMsg;
          if (isArray(ErrMsg)) {
            messageNew = get(error, 'response.data.message[0].messages[0].message')
          }
          setShowToaster(true);
          setToasterData({
            message: messageNew || 'Server error!',
            heading: props.menu,
            variant: 'danger'
          })
          return Promise.reject(error);
        }
      } else {
        try {
          submittedResult = await HandleSubmit(event, { ...props.additionalParams, ...additionalParams });
          if (additionalParams.isPageRedirect && additionalParams.pageRedirectUrl) {
            GoToPage(additionalParams.pageRedirectUrl);
          }
          return Promise.resolve({ ...{}, submittedResult });
        } catch (error) {
          const ErrMsg = get(error, 'response.data.message');
          let messageNew = ErrMsg;
          if (isArray(ErrMsg)) {
            messageNew = get(error, 'response.data.message[0].messages[0].message')
          }
          setShowToaster(true);
          setToasterData({
            message: messageNew || 'Server error!',
            heading: props.menu,
            variant: 'danger'
          })
          return Promise.reject(error);
        }
      }
    },

    SetPublishActionAvailability = (flag) => {
      setEnablePublishAction(flag);
    },

    SetPublishActionItem = (item) => {
      setEditItem(item);
    }

  return (
    <>
      {
        Loading ?
          <LoadingComp {...props}/> :
          (
            <div className="main-content">
              <div className="page-content">
                {
                  props.SkipDefaultHeader ? (
                    ""
                  ) : (
                    <TopNav
                      menu={props.menu}
                      parentPath={props.parentPath}
                      pageType={props.pageType}
                      published_at={editItem.published_at}
                      editItemId={editItem.id}
                      enablePublishAction={enablePublishAction}
                      SetPublishActionItem={SetPublishActionItem}
                      triggerValidation={props.getPublishingValidation}
                      groupMenu={props.groupMenu}
                    />
                  )
                }
                {PuFormControlsEdit(HandleSubmitFunc, SetPublishActionAvailability, editItem)}
              </div>
            </div>

          )
      }
      {
        showToaster && <Toaster
          handleClick={showToaster}
          setShowToast={(val) => setShowToasterFunc(val)}
          variant={toasterData.variant}
          heading={toasterData.heading}
          message={toasterData.message}
        />
      }
    </>
  )
}

export default CommonEditItemComp;
import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import PropertiesEdit from "@components/properties/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "properties.edit"
}

const PropertiesEditPage = (props) => (
  <>
    <Seo title="Edit Property" />
    <div className="main-wrapper">
      <Sidebar menuActive="properties"
        action="Edit" />
      <PropertiesEdit
        menu="Edit Property Details"
        parentPath="properties"
        pageType="Edit"
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default PropertiesEditPage;
